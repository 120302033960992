
import { defineComponent, reactive, onMounted } from 'vue';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import ModelViewer from "@/components/ModelViewer.vue";
import FileDrop from "@/components/FileDrop.vue";
import axios from "axios";
import { Vector3 } from 'three';

export default defineComponent({
  name: 'IndexView',
  components: {
    ModelViewer,
    FileDrop,
    Splitpanes, Pane
  },
  setup() {
    const state = reactive<{
      dialog: boolean;
      files: File[];
      syncCamera: boolean;
      syncedCameraTransform: {
        position: Vector3;
        target: Vector3;
        zoom: number;
      };
      inquiry: boolean;
    }>({
      dialog: false,
      files: [],
      syncCamera: false,
      syncedCameraTransform: {
        position: new Vector3(0, 0, 200),
        target: new Vector3(),
        zoom: 1
      },
      inquiry: false,
    });

    const submit = (files: File[]) => {
      state.files = files;
      console.log(files);
    };

    onMounted(async () => {
      state.dialog = true;

      /*
      const path = '/models/NUI17690157_sample.stl';
      // const path = '/models/head.obj';
      const { data } = await axios.get(path, { responseType: 'arraybuffer' });
      const file = new File([data], path);
      state.files.push(file);
      state.files.push(file);
      state.dialog = false;
      */
    });

    return {
      state,
      upload: () => {
        state.dialog = true;
      },
      removeAll: () => {
        state.files = [];
      },
      submit,
      updateCameraTransform: (tr: {
        position: Vector3;
        target: Vector3;
        zoom: number;
      }) => {
        if (state.syncCamera) {
          state.syncedCameraTransform = tr;
        }
      }
    };
  }
});
