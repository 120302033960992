

import { ref, defineComponent, reactive, onMounted, watch, nextTick } from 'vue';
import { Metrics } from '@/misc/Metrics';

export default defineComponent({
  name: "MetricsViewer",
  props: {
    metrics: {
      type: Metrics,
      required: false,
      default: null
    },
  },
  emits: [
    "reset",
  ],
  setup(props) {
    const section = ref<SVGSVGElement>();

    onMounted(() => {
    });

    watch(() => props.metrics, (current) => {
      const element = section.value!;
      while (element.firstChild !== null) {
        if (element.lastChild !== null) {
          element.removeChild(element.lastChild);
        }
      }
      nextTick(() => {
        const svg = current.section;
        if (svg !== undefined) {
          element.appendChild(svg);
          const bb = svg.getBBox();
          const rect = element.getBoundingClientRect();
          element.style.height = `${bb.height + 8}px`;
          svg.setAttribute('transform', `translate(${(-bb.x + rect.width - bb.width) * 0.5}, 0)`);
        }
      });
    });

    return {
      section
    };
  }
});

