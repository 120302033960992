import { Vector2 } from 'three';
import { Line2D } from './Line2D';

export class Segment2D {
  p0: Vector2; // from
  p1: Vector2; // to

  constructor (p0: Vector2, p1: Vector2) {
    this.p0 = p0;
    this.p1 = p1;
  }

  toLine (): Line2D {
    const line = new Line2D();
    line.setFromPoints(this.p0, this.p1);
    return line;
  }

  length (): number {
    const dx = (this.p0.x - this.p1.x);
    const dy = (this.p0.y - this.p1.y);
    return Math.sqrt(dx * dx + dy * dy);
  }

  getSegmentIntersectionPoint (segment: Segment2D): { result: boolean, point: Vector2 | null } {
    const l0 = this.toLine();
    const l1 = segment.toLine();
    const ret = segment.intersectsByLine(l0) && this.intersectsByLine(l1);
    if (!ret) {
      return {
        result: ret,
        point: null
      };
    }

    const point = l0.getIntersectionPoint(l1);
    return {
      result: ret && (point !== null),
      point
    };
  }

  intersectsBySegment (segment: Segment2D): boolean {
    const l0 = this.toLine();
    const l1 = segment.toLine();
    return segment.intersectsByLine(l0) && this.intersectsByLine(l1);
  }

  intersectsByLine (l: Line2D): boolean {
    const t1 = l.a * this.p0.x + l.b * this.p0.y + l.c;
    const t2 = l.a * this.p1.x + l.b * this.p1.y + l.c;
    return t1 * t2 <= 0;
  }
}
