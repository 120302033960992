import { BufferGeometry, Color, Object3D, OrthographicCamera, Plane, Points, PointsMaterial, TextureLoader, Vector2, Vector3 } from "three";
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { ReferencePoint } from "./ReferencePoint";
import { TypedEvent } from "./TypedEvent";

export class Anchor extends Object3D {

  public get offset(): number {
    return 12.5;
  }

  public get plane(): Plane {
    return this._plane;
  }

  public get directions(): Vector3[] {
    return this._directions;
  }

  public nosePoint: Vector3;
  public leftEarPoint: Vector3;
  public rightEarPoint: Vector3;
  private _directions: Vector3[] = [
    new Vector3(0, 0, 1),
    new Vector3(1, 0, 0),
    new Vector3(-1, 0, 0)
  ];

  private _plane: Plane;
  private _points: Points[] = [];
  private _pins: Line2[] = [];

  private readonly _defaultPointSize: number = 10;
  private readonly _highlightedPointSize: number = 20;
  public onUpdate: TypedEvent<Anchor> = new TypedEvent();

  constructor(nosePoint: Vector3, rightEarPoint: Vector3, leftEarPoint: Vector3) {
    super();

    this.nosePoint = nosePoint;
    this.rightEarPoint = rightEarPoint;
    this.leftEarPoint = leftEarPoint;

    this._plane = new Plane();
    this._plane.setFromCoplanarPoints(nosePoint, leftEarPoint, rightEarPoint);

    for (let i = 0; i < 3; i++) {
      const geom = new BufferGeometry();
      const point = new Points(
        geom,
        new PointsMaterial({
          size: this._defaultPointSize,
          color: new Color(0xFF0000),
          transparent: true,
          map: new TextureLoader().load('/textures/circle128.png')
        })
      );
      this.add(point);

      const lgeom = new LineGeometry();
      const pin = new Line2(
        lgeom,
        new LineMaterial({
          color: new Color(0xFF0000).getHex(),
          linewidth: 1
        })
      );
      this.add(pin);

      this._points.push(point);
      this._pins.push(pin);
    }

    this.update();
  }

  public set(point: Vector3, index: number): void {
    const points = this.points();
    if (index >= 0 && index < points.length) {
      points[index].copy(point);
    }
  }

  private points(): Vector3[] {
    return [this.nosePoint, this.leftEarPoint, this.rightEarPoint];
  }

  public getOffsetPoints(): Vector3[] {
    return this.points().map((p, idx) => {
      return p.clone().add(this._directions[idx].clone().multiplyScalar(this.offset));
    });
  }

  public highlight(point: Vector3): void {
    const idx = this.index(point);
    const m = this._points[idx].material as PointsMaterial;
    m.size = this._highlightedPointSize;
  }

  public unhighlight(): void {
    this._points.forEach((p) => {
      const m = p.material as PointsMaterial;
      m.size = this._defaultPointSize;
    });
  }

  getSize(): number {
    return this._defaultPointSize;
  }

  setSize(size: number): void {
    this._points.forEach((p) => {
      const m = p.material as PointsMaterial;
      m.size = size;
    });
  }

  setResolution(resolution: Vector2): void {
    this._pins.forEach((pin) => {
      pin.material.resolution.copy(resolution);
    });
  }

  public index(target: Vector3): number {
    const points = this.points();
    const distances = points.map((p) => {
      return p.distanceToSquared(target);
    });
    const d = Math.min(...distances);
    return distances.indexOf(d);
  }

  public closest(target: Vector3): Vector3 {
    const points = this.points().slice();
    points.sort((p0, p1) => {
      const d0 = p0.distanceToSquared(target);
      const d1 = p1.distanceToSquared(target);
      if (d0 < d1) { return -1; }
      return 1;
    });
    return points[0];
  }

  public intersects(camera: OrthographicCamera, size: Vector2, e: Vector2): Vector3 | undefined {
    const forward = new Vector3(0, 0, -1);
    forward.applyQuaternion(camera.quaternion);

    const points = this.getOffsetPoints();

    const index = points.findIndex((p, i) => {
      const screen = p.clone().project(camera);
      const x = (screen.x + 1) * size.x / 2;
      const y = -(screen.y - 1) * size.y / 2;
      const dx = x - e.x;
      const dy = y - e.y;
      const d = Math.sqrt(dx * dx + dy * dy);
      const dir = this._directions[i];
      // Use dot product of camera.forward & point direction to detect intersections.
      const dot = forward.dot(dir);
      return d < 10 && dot <= 0;
    });

    if (index >= 0) {
      return points[index];
    }

    return undefined;
  }

  public update(): void {
    const points = this.points();
    const offsets = this.getOffsetPoints();

    this._points.forEach((point, idx) => {
      point.geometry.setFromPoints([offsets[idx]]);
    });

    this._pins.forEach((pin, idx) => {
      const positions: number[] = [];
      [
        points[idx], offsets[idx]
      ].forEach((el) => {
        positions.push(el.x, el.y, el.z);
      });
      pin.geometry.setPositions(positions);
      pin.computeLineDistances();
      pin.visible = true;
    });

    this._plane.setFromCoplanarPoints(this.nosePoint, this.leftEarPoint, this.rightEarPoint);
    this.onUpdate.emit(this);
  }

  public pointOnly(offset = 0): void {
    this._pins.forEach((pin) => {
      pin.visible = false;
    });

    const points = this.points().map((p, idx) => {
      return p.clone().add(this._directions[idx].clone().multiplyScalar(offset));
    });
    this._points.forEach((point, idx) => {
      point.geometry.setFromPoints([points[idx]]);
    });
  }

  public serialize(): ReferencePoint {
    return {
      rt_ear_pos: this.rightEarPoint,
      lt_ear_pos: this.leftEarPoint,
      nasal_root_pos: this.nosePoint
    };
  }

}