import { ArrowHelper, BufferGeometry, Color, Line, LineBasicMaterial, Plane, Points, PointsMaterial, Scene, Vector2, Vector3 } from 'three';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Anchor } from '../misc/Anchor';
import TextSprite from '../elements/TextSprite';
import { CubicHermiteCurve } from './CubicHermiteCurve';
import GeometryUtils from './GeometryUtils';
import { Line2D } from './Line2D';
import { PolylineCurve } from './PolylineCurve';
import { Segment2D } from './Segment2D';
import { NurbsCurve } from './NurbsCurve';

export class Layer {
  plane: Plane;
  dx: Vector3;
  dy: Vector3;

  curve: PolylineCurve;
  segments: Segment2D[];

  constructor (plane: Plane, points: Vector3[]) {
    this.plane = plane;
    this.curve = new PolylineCurve(points);

    const { dx, dy } = GeometryUtils.getPlaneAxes(this.plane);
    this.dx = dx;
    this.dy = dy;

    const p2d = this.curve.points.map((p) => {
      return this.project(p);
    });
    const segments: Segment2D[] = [];
    const n = p2d.length;
    for (let i = 0; i < n - 1; i++) {
      const p0 = p2d[i];
      const p1 = p2d[i + 1];
      segments.push(new Segment2D(p0, p1));
    }
    // insert last segment to close
    segments.push(new Segment2D(p2d[n - 1], p2d[0]));

    this.segments = segments;
  }

  public get points (): Vector3[] {
    return this.curve.points;
  }

  public divide (anchor: Anchor): {
    area: {
      rt: number;
      rb: number;
      lb: number;
      lt: number;
    };
    points: {
      rt: Vector3[];
      rb: Vector3[];
      lb: Vector3[];
      lt: Vector3[];
    };
  } {
    const {
      leftPoint, rightPoint, leftSegment, rightSegment,
      bottomPoint, topPoint, bottomSegment, topSegment,
      midPoint
    } = this.getAxes(anchor);
    const rtPoly = this.getSubAreaPolyline(topSegment, topPoint, rightSegment, rightPoint);
    const rbPoly = this.getSubAreaPolyline(rightSegment, rightPoint, bottomSegment, bottomPoint);
    const lbPoly = this.getSubAreaPolyline(bottomSegment, bottomPoint, leftSegment, leftPoint);
    const ltPoly = this.getSubAreaPolyline(leftSegment, leftPoint, topSegment, topPoint);

    // make a polyline close at mid point
    const area = {
      rt: this.calculatePolyline2DArea(rtPoly.concat([midPoint, topPoint])),
      rb: this.calculatePolyline2DArea(rbPoly.concat([midPoint, rightPoint])),
      lb: this.calculatePolyline2DArea(lbPoly.concat([midPoint, bottomPoint])),
      lt: this.calculatePolyline2DArea(ltPoly.concat([midPoint, leftPoint]))
    };

    const points = {
      rt: rtPoly.map(p => this.unproject(p)),
      rb: rbPoly.map(p => this.unproject(p)),
      lb: lbPoly.map(p => this.unproject(p)),
      lt: ltPoly.map(p => this.unproject(p))
    };

    return {
      area, points
    };
  }

  private getSubAreaPolyline (startSegment: Segment2D, startPoint: Vector2, endSegment: Segment2D, endPoint: Vector2): Vector2[] {
    const n = this.segments.length;
    const startI = this.segments.indexOf(startSegment);
    const endI = this.segments.indexOf(endSegment);

    let direction = 1;
    let interval = 0;
    if (startI < endI) {
      const n0 = endI - startI;
      const n1 = startI + (n - endI);
      if (n0 < n1) {
        direction = 1;
        interval = n0;
      } else {
        direction = -1;
        interval = n1;
      }
    } else {
      const n0 = startI - endI;
      const n1 = endI + (n - startI);
      if (n0 < n1) {
        direction = -1;
        interval = n0;
      } else {
        direction = 1;
        interval = n1;
      }
    }
    // const interval = (startI < endI) ? (endI - startI) : (n - startI - 1 + endI);

    // the polyline starts from 'startPoint'
    const result: Vector2[] = [startPoint];

    // CAUTION: ignore first segment
    for (let i = 1; i < interval; i++) {
      let index = (startI + direction * i);
      if (index < 0) {
        index = n + index;
      }
      index = index % n;
      const segment = this.segments[index];
      result.push(segment.p1);
    }

    // the polyline ends in 'endPoint'
    result.push(endPoint);

    return result;
  }

  private calculatePolyline2DArea (polyline: Vector2[]): number {
    let area = 0;
    for (let i = 0, n = polyline.length; i < n; i++) {
      const p0 = polyline[i];
      const p1 = polyline[(i + 1) % n];
      area += (p1.x + p0.x) * (p1.y - p0.y);
    }
    return Math.abs(area / 2.0);
  }

  public area (): number {
    const points = this.curve.points;
    const p2d = points.map((p) => {
      return this.project(p);
    });
    let area = 0;
    for (let i = 0, n = p2d.length; i < n; i++) {
      const p0 = p2d[i];
      const p1 = p2d[(i + 1) % n];
      area += (p1.x + p0.x) * (p1.y - p0.y);
    }
    return Math.abs(area / 2.0);
  }

  public getAxes (anchor: Anchor): {
    xaxis: Line2D;
    leftPoint: Vector2; rightPoint: Vector2;
    leftSegment: Segment2D; rightSegment: Segment2D;
    yaxis: Line2D;
    bottomPoint: Vector2; topPoint: Vector2;
    bottomSegment: Segment2D; topSegment: Segment2D;
    midPoint: Vector2;
  } {
    const t2d = this.project(anchor.nosePoint);
    const r2d = this.project(anchor.rightEarPoint);
    const l2d = this.project(anchor.leftEarPoint);

    // MEMO: "鼻"と"両耳を結んだ中点"を通る線を基準線とする
    const { mid: midPoint, axis: yaxis } = this.getBasisAxis(t2d, r2d, l2d);
    const yseg = this.segments.filter((s) => {
      return s.intersectsByLine(yaxis);
    });
    if (yseg.length !== 2) { throw new Error(`y axis error: ${yseg.length}`); }
    let bottomSegment: Segment2D;
    let topSegment: Segment2D;
    if (yseg[0].p0.y < yseg[1].p0.y) {
      bottomSegment = yseg[0];
      topSegment = yseg[1];
    } else {
      bottomSegment = yseg[1];
      topSegment = yseg[0];
    }

    const bottomPoint = bottomSegment.toLine().getIntersectionPoint(yaxis);
    const topPoint = topSegment.toLine().getIntersectionPoint(yaxis);
    if (bottomPoint === null || topPoint === null) { throw new Error(`y axis error: ${bottomPoint === null} || ${topPoint === null}`); }

    const dir = topPoint.clone().sub(bottomPoint);
    const xaxis = new Line2D().setFromPoints(midPoint, midPoint.clone().add(new Vector2(dir.y, -dir.x)));
    const xseg = this.segments.filter((s) => {
      return s.intersectsByLine(xaxis);
    });
    if (xseg.length !== 2) { throw new Error(`x axis error: ${xseg.length}`); }

    let leftSegment: Segment2D;
    let rightSegment: Segment2D;

    // CAUTION:
    // x positive is left
    // x negative is right
    if (xseg[0].p0.x > xseg[1].p0.x) {
      leftSegment = xseg[0];
      rightSegment = xseg[1];
    } else {
      leftSegment = xseg[1];
      rightSegment = xseg[0];
    }

    const leftPoint = leftSegment.toLine().getIntersectionPoint(xaxis);
    const rightPoint = rightSegment.toLine().getIntersectionPoint(xaxis);
    if (leftPoint === null || rightPoint === null) { throw new Error('x axis error'); }

    return {
      xaxis,
      leftPoint,
      rightPoint,
      leftSegment,
      rightSegment,
      yaxis,
      bottomPoint,
      topPoint,
      bottomSegment,
      topSegment,
      midPoint
    };
  }

  // 頭囲
  public length (): number {
    let result = 0;
    this.segments.forEach((s) => {
      result += s.length();
    });
    if (this.segments.length >= 2) {
      const s0 = this.segments[0];
      const s1 = this.segments[this.segments.length - 1];
      result += s1.p1.distanceTo(s0.p0);
    }
    return result;
  }

  public ca (anchor: Anchor): {
    ca: number;
    cvai: number;
    basis: { from: Vector3; to: Vector3; };
    A: { from: Vector3; to: Vector3; };
    B: { from: Vector3; to: Vector3; };
    debug?: any;
  } {
    const t2d = this.project(anchor.nosePoint);
    const r2d = this.project(anchor.rightEarPoint);
    const l2d = this.project(anchor.leftEarPoint);

    const { mid, axis } = this.getBasisAxis(t2d, r2d, l2d);
    const points = this.getIntersectionPoints(this.segments, axis);
    const y0 = points[0];
    const offset = Math.PI * (30 / 180);
    const axis0 = this.getRotatedAxis(mid, y0, offset);
    const axis1 = this.getRotatedAxis(mid, y0, -offset);

    const pa = this.getIntersectionPoints(this.segments, axis0).map(p => this.unproject(p));
    const pb = this.getIntersectionPoints(this.segments, axis1).map(p => this.unproject(p));
    const A = new Vector3().subVectors(pa[0].clone(), pa[1].clone()).length();
    const B = new Vector3().subVectors(pb[0].clone(), pb[1].clone()).length();

    const dA = A > B ? A : B;
    const dB = A > B ? B : A;

    return {
      ca: Math.abs(A - B),
      cvai: (dA - dB) / dA * 100,
      basis: {
        from: this.unproject(points[0]),
        to: this.unproject(points[1])
      },
      A: {
        from: pa[0], to: pa[1]
      },
      B: {
        from: pb[0], to: pb[1]
      },
      debug: pa
    };
  }

  // 短頭率
  public ci (anchor: Anchor): {
    ci: number; // L / W
    brachycephaly: number; // W / L
    L: number; // 前後径
    W: number; // 左右径
    xaxis: { from: Vector3; to: Vector3; };
    yaxis: { from: Vector3; to: Vector3; };
    debug?: any;
  } {
    /*
    // correct
    return p2d.map(p => {
      return this.unproject(p, dx, dy);
    });
    */

    // correct
    // return [t2d, xmid, r2d, l2d].map(p => this.unproject(p, dx, dy));

    // MEMO: 鼻と両耳を結んだ中点を通る線を基準線とする
    const { leftPoint, rightPoint, bottomPoint, topPoint } = this.getAxes(anchor);

    const xp3d = [leftPoint, rightPoint].map((p) => {
      return this.unproject(p as Vector2);
    });
    const yp3d = [bottomPoint, topPoint].map((p) => {
      return this.unproject(p as Vector2);
    });

    const W = new Vector3().subVectors(xp3d[0], xp3d[1]).length();
    const L = new Vector3().subVectors(yp3d[0], yp3d[1]).length();

    return {
      ci: W / L * 100,
      brachycephaly: L / W * 100,
      L,
      W,
      xaxis: {
        from: xp3d[0],
        to: xp3d[1]
      },
      yaxis: {
        from: yp3d[0],
        to: yp3d[1]
      },
      debug: xp3d[0].clone().add(xp3d[1]).multiplyScalar(0.5)
    };
  }

  private getBasisAxis (t2d: Vector2, r2d: Vector2, l2d: Vector2): {
    mid: Vector2;
    axis: Line2D;
  } {
    // MEMO: 鼻と両耳を結んだ中点を通る線を基準線とする
    const mid = l2d.clone().add(r2d).multiplyScalar(0.5);
    const axis = new Line2D().setFromPoints(t2d, mid);
    return {
      mid,
      axis
    };
  }

  private getRotatedAxis (from: Vector2, to: Vector2, theta: number): Line2D {
    const cos = Math.cos(theta);
    const sin = Math.sin(theta);
    const d0x = to.x - from.x;
    const d0y = to.y - from.y;
    const p0 = from.clone().add(new Vector2(d0x * cos - d0y * sin, d0x * sin + d0y * cos));
    return new Line2D().setFromPoints(from, p0);
  }

  private getIntersectionPoints (segments: Segment2D[], line: Line2D): Vector2[] {
    const intersected = segments.filter((s) => {
      return s.intersectsByLine(line);
    });
    return intersected.map(s => s.toLine().getIntersectionPoint(line)) as Vector2[];
  }

  public unproject (p: Vector2): Vector3 {
    const x = this.dx.clone().multiplyScalar(p.x);
    const y = this.dy.clone().multiplyScalar(p.y);
    return this.plane.normal.clone().multiplyScalar(-this.plane.constant).add(x).add(y);
  }

  public project (p: Vector3): Vector2 {
    const proj = p.clone().projectOnPlane(this.plane.normal);
    const x = this.dx.dot(proj);
    const y = this.dy.dot(proj);
    return new Vector2(x, y);
  }

  public union (anchor: Anchor, factor: number = 1, debug: any): Layer {
    const {
      midPoint
    } = this.getAxes(anchor);

    let top = -1e6; let bottom = 1e6;

    const { points } = this.divide(anchor);
    const { lb, lt, rt, rb } = points;

    // remove duplication
    lb.pop();
    lt.shift();
    const leftSide = lb.concat(lt).map(p => this.project(p));
    const leftSegments: Segment2D[] = [];
    for (let i = 0; i < leftSide.length - 1; i++) {
      const ia = i; const ib = i + 1;
      top = Math.max(top, leftSide[ia].y);
      bottom = Math.min(bottom, leftSide[ia].y);
      top = Math.max(top, leftSide[ib].y);
      bottom = Math.min(bottom, leftSide[ib].y);
      leftSegments.push(new Segment2D(leftSide[ia], leftSide[ib]));
    }

    // remove duplication
    rt.pop();
    rb.shift();
    const rightSide = rt.concat(rb).map(p => this.project(p));
    const rightSegments: Segment2D[] = [];
    for (let i = 0; i < rightSide.length - 1; i++) {
      const ia = i; const ib = i + 1;
      top = Math.max(top, rightSide[ia].y);
      bottom = Math.min(bottom, rightSide[ia].y);
      top = Math.max(top, rightSide[ib].y);
      bottom = Math.min(bottom, rightSide[ib].y);
      rightSegments.push(new Segment2D(rightSide[ia], rightSide[ib]));
    }

    const offset = 1e-1 * 5;
    const length = (top - bottom);
    const resolution = 64;
    const unit = (length / resolution);
    const side2D: Vector2[] = [];

    const proj = this.points.map(p => this.project(p));
    const min = proj.reduce((prev, current) => {
      return (prev.y < current.y) ? prev : current;
    });
    const max = proj.reduce((prev, current) => {
      return (prev.y > current.y) ? prev : current;
    });

    side2D.push(new Vector2(midPoint.x - (Math.abs(midPoint.x - min.x) * factor), bottom));

    for (let i = 1; i < resolution; i++) {
      // const y = i * unit + bottom + offset;
      const y = i * unit + bottom;
      const a = new Vector2(-1e4, y);
      const b = new Vector2(1e4, y);
      const line = new Line2D().setFromPoints(a, b);
      const left = leftSegments.find(s => s.intersectsByLine(line));
      const right = rightSegments.find(s => s.intersectsByLine(line));
      if (left !== undefined && right !== undefined) {
        const l = left.toLine().getIntersectionPoint(line);
        const r = right.toLine().getIntersectionPoint(line);
        if (l !== null && r !== null) {
          const dlx = Math.abs(midPoint.x - l.x);
          const drx = Math.abs(midPoint.x - r.x);
          const x = midPoint.x - (Math.max(dlx, drx) * factor);
          side2D.push(new Vector2(x, y));
        }
      } else {
        // ignore if not intersected
        /*
        const pa = this.unproject(a);
        const pb = this.unproject(b);
        const geom = new BufferGeometry();
        geom.setFromPoints([pa, pb]);
        const line = new Line(geom, new LineBasicMaterial({
          color: new Color(0x00ff00)
        }));
        debug.add(line);
        */
      }
    }

    side2D.push(new Vector2(midPoint.x - (Math.abs(midPoint.x - max.x) * factor), top));

    // mirroring in y axis
    const otherSide2D = side2D.map((p) => {
      const dx = midPoint.x - p.x;
      const point = new Vector2(midPoint.x + dx, p.y);
      return point.clone().sub(midPoint).add(midPoint);
    });
    otherSide2D.reverse();

    const sequence = side2D.concat(otherSide2D);
    return new Layer(this.plane, sequence.map(p => this.unproject(p)));
  }

  public resample (divisions: number = 128): Layer {
    const n = NurbsCurve.byPoints(this.points, 2);
    const resamples = new Array(divisions).fill(0).map((_, idx) => idx / divisions).map((u) => {
      return n.getPointAt(u);
    });
    return new Layer(this.plane, resamples);
  }

  public inflate (anchor: Anchor, angle: number, divisions: number = 128, debug: any | undefined = undefined): Layer | undefined {
    const {
      midPoint,
      yaxis, topPoint, bottomPoint
    } = this.getAxes(anchor);

    let p0 = this.unproject(bottomPoint.clone().rotateAround(midPoint, -angle));
    let p1 = this.unproject(bottomPoint.clone().rotateAround(midPoint, angle));

    /*
    debug.add(
      new Points(new BufferGeometry().setFromPoints([p0, p1]), new PointsMaterial({
        size: 5,
        color: new Color(0xFF0000)
      }))
    );
    */

    // Get start & end tangent vectors from nurbs
    const nurbs = this.curve.toNurbs();

    /*
    this.displayPointIndices(this.curve.points).forEach(t => {
      debug.add(t);
    });
    */

    // TODO: fix a bug
    const u0 = nurbs.getClosestParam(p0);
    const u1 = nurbs.getClosestParam(p1);

    if (u0 === undefined || u1 === undefined) {
      // console.log('closest params not found');
      return undefined;
    }

    p0 = nurbs.getPointAt(u0);
    p1 = nurbs.getPointAt(u1);

    /*
    debug.add(
      new Points(new BufferGeometry().setFromPoints([p0, p1]), new PointsMaterial({
        size: 5,
        color: new Color(0x00FF00)
      }))
    );
    */

    const d0 = nurbs.getTangentAt(u0);
    const d1 = nurbs.getTangentAt(u1);

    /*
    // debug
    const c0 = d0.clone().multiplyScalar(2.0).addScalar(-0.5);
    const h0 = new ArrowHelper(d0, p0, 10, new Color(c0.x, c0.y, c0.z));
    scene.add(h0);

    const c1 = d1.clone().multiplyScalar(2.0).addScalar(-0.5);
    const h1 = new ArrowHelper(d1, p1, 10, new Color(c1.x, c1.y, c1.z));
    scene.add(h1);
    */

    const distance = p0.distanceTo(p1);
    const scale = distance;
    let points: Vector3[] = [];
    const delta = 0.01;

    for (let t = u0; t < u1; t += delta) {
      const p = nurbs.getPointAt(t);
      points.push(p);
    }

    const interval = (u0 + (1 - u1));
    // console.log(u0, u1, Math.floor(interval / delta));
    const count = Math.max(0, Math.floor(interval / delta));
    // const interpolator = new CubicHermiteCurve(p0, d0.clone().multiplyScalar(scale), p1, d1.clone().multiplyScalar(scale));
    const interpolator = new CubicHermiteCurve(p1, d1.clone().multiplyScalar(scale), p0, d0.clone().multiplyScalar(scale));
    const interpolated = new Array(count).fill(0).map((_, idx) => idx / count).map((t) => {
      return interpolator.getPointAt(t);
    });
    points = points.concat(interpolated);

    /*
    for (let t = u1; t < 1; t += delta) {
      const p = nurbs.getPointAt(t);
      points.push(p);
    }
    */

    return new Layer(this.plane, points);
  }

  public shrink (center: Vector3, weight: number): Layer {
    const c = this.project(center);
    const points = this.points.map(p => this.project(p)).map((p) => {
      return p.clone().add(c.clone().sub(p).multiplyScalar(weight));
    });
    return new Layer(this.plane, points.map(p => this.unproject(p)));
  }

  public toSVG (anchor: Anchor, options: {
    strokeColor?: string;
  } = { strokeColor: '#33aafa' }): {
    element: SVGGElement;
    min: { x: number; y: number };
    max: { x: number; y: number };
    offset: Vector2;
  } {
    const element = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    const axes = this.getAxes(anchor);
    const padding = 4;
    const { topPoint, rightPoint, bottomPoint, leftPoint } = axes;

    const yax = topPoint.clone().sub(bottomPoint);
    const angle = yax.angle();
    const aoffset = Math.PI * 0.5 - angle;
    const ymid = topPoint.clone().add(bottomPoint).multiplyScalar(0.5);
    yax.rotateAround(ymid, aoffset);
    const yoff = yax.clone().setLength(yax.length() * 0.5);
    topPoint.copy(ymid.clone().add(yoff));
    bottomPoint.copy(ymid.clone().sub(yoff));

    const xax = rightPoint.clone().sub(leftPoint);
    const xmid = rightPoint.clone().add(leftPoint).multiplyScalar(0.5);
    xax.rotateAround(xmid, aoffset);
    const xoff = xax.clone().setLength(xax.length() * 0.5);
    rightPoint.copy(xmid.clone().add(xoff));
    leftPoint.copy(xmid.clone().sub(xoff));

    const offset = new Vector2(rightPoint.x - padding, bottomPoint.y - padding);
    // const offset = new Vector2(axes.leftPoint.x - padding, 0);

    const xpoly = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    const ypoly = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
    const cpoly = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');

    xpoly.setAttribute('fill', 'none');
    ypoly.setAttribute('fill', 'none');
    cpoly.setAttribute('fill', 'none');

    xpoly.setAttribute('stroke', '#333');
    ypoly.setAttribute('stroke', '#333');
    cpoly.setAttribute('stroke', options.strokeColor ?? '#33aafa');

    const min = new Vector2(1e10, 1e10);
    const max = new Vector2(-1e10, -1e10);

    [leftPoint, rightPoint].forEach((p) => {
      const point = svg.createSVGPoint();
      point.x = p.x - offset.x;
      point.y = p.y - offset.y;
      // point.y = p.y;
      xpoly.points.appendItem(point);

      min.x = Math.min(min.x, point.x); min.y = Math.min(min.y, point.y);
      max.x = Math.max(max.x, point.x); max.y = Math.max(max.y, point.y);
    });

    [topPoint, bottomPoint].forEach((p) => {
      const point = svg.createSVGPoint();
      point.x = p.x - offset.x;
      point.y = p.y - offset.y;
      // point.y = p.y;
      ypoly.points.appendItem(point);

      min.x = Math.min(min.x, point.x); min.y = Math.min(min.y, point.y);
      max.x = Math.max(max.x, point.x); max.y = Math.max(max.y, point.y);
    });

    if (this.segments.length > 0) {
      const s = this.segments[0];
      const point = svg.createSVGPoint();
      point.x = s.p0.x - offset.x;
      point.y = s.p0.y - offset.y;
      // point.y = s.p0.y;
      cpoly.points.appendItem(point);
    }

    this.segments.forEach((s) => {
      const point = svg.createSVGPoint();
      point.x = s.p1.x - offset.x;
      point.y = s.p1.y - offset.y;
      // point.y = s.p1.y;
      cpoly.points.appendItem(point);

      min.x = Math.min(min.x, point.x); min.y = Math.min(min.y, point.y);
      max.x = Math.max(max.x, point.x); max.y = Math.max(max.y, point.y);
    });

    element.append(cpoly);
    element.append(xpoly);
    element.append(ypoly);

    /*
    const l2d = this.project(anchor.leftEarPoint);
    const r2d = this.project(anchor.rightEarPoint);
    element.append(this.createCircle(this.project(anchor.nosePoint), offset, 4, '#000000'));
    element.append(this.createCircle(axes.midPoint, offset, 4, '#aaaaaa'));
    element.append(this.createCircle(axes.rightPoint, offset, 4, '#ff0000'));
    element.append(this.createCircle(r2d, offset, 4, '#ff44f4'));
    element.append(this.createCircle(axes.leftPoint, offset, 4, '#00ff00'));
    element.append(this.createCircle(l2d, offset, 4, '#44fff4'));
    */

    return {
      element, min, max, offset
    };
  }

  private createCircle (p: Vector2, offset: Vector2, r: number = 4, color: string = '#ff0000') {
    const c = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
    c.setAttribute('cx', `${p.x - offset.x}px`);
    c.setAttribute('cy', `${p.y - offset.y}px`);
    c.setAttribute('r', `${r}`);
    c.setAttribute('fill', `${color}`);
    return c;
  }

  public toGeometry (): LineGeometry {
    const geom = new LineGeometry();
    const points = this.points.slice();
    points.push(this.points[0]);

    const positions: number[] = [];
    points.forEach((el) => {
      positions.push(el.x, el.y, el.z);
    });
    geom.setPositions(positions);
    return geom;
  }

  private displayPointIndices (points: Vector3[], size: number = 1.0, color: string = '#000000'): TextSprite[] {
    return points.map((point, index) => {
      const text = new TextSprite(index.toString(), color, size);
      text.position.copy(point);
      return text;
    });
  }
}
