import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e655c92"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "axis",
  viewBox: "0 0 100 100",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = {
  ref: "lx",
  x1: "50",
  y1: "50",
  x2: "50",
  y2: "50",
  "stroke-width": "2",
  stroke: "#ff2b56"
}
const _hoisted_3 = {
  ref: "ly",
  x1: "50",
  y1: "50",
  x2: "50",
  y2: "50",
  "stroke-width": "2",
  stroke: "#19bf6c"
}
const _hoisted_4 = {
  ref: "lz",
  x1: "50",
  y1: "50",
  x2: "50",
  y2: "50",
  "stroke-width": "2",
  stroke: "#428dff"
}
const _hoisted_5 = ["cx", "cy", "onClick"]
const _hoisted_6 = ["x", "y"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", null, [
      _createElementVNode("line", _hoisted_2, null, 512),
      _createElementVNode("line", _hoisted_3, null, 512),
      _createElementVNode("line", _hoisted_4, null, 512),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.elements, (el, idx) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (el.type === "circle")
            ? (_openBlock(), _createElementBlock("circle", {
                key: idx,
                r: 12,
                cx: el.x,
                cy: el.y,
                class: _normalizeClass(el.cls),
                onClick: ($event: any) => (_ctx.click(el))
              }, null, 10, _hoisted_5))
            : _createCommentVNode("", true),
          (el.type === 'text')
            ? (_openBlock(), _createElementBlock("text", {
                key: idx,
                x: el.x,
                y: el.y,
                "font-size": "12"
              }, _toDisplayString(el.content), 9, _hoisted_6))
            : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ])
  ]))
}