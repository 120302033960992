// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '../styles/variables.scss'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: 'jmc',
      themes: {
        jmc: {
          colors: {
            primary: '#ea5504'
          },
        }
      }
    }
  }
)
