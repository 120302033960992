import { BufferAttribute, BufferGeometry, Plane, Vector3 } from 'three';

export default {

  mergeVertices (geometry: BufferGeometry, tolerance: number = 1e-4): BufferGeometry {
    tolerance = Math.max(tolerance, Number.EPSILON);

    // Generate an index buffer if the geometry doesn't have one, or optimize it
    // if it's already available.
    const hashToIndex: { [index:string]: any } = {};
    const indices = geometry.getIndex();
    const positions = geometry.getAttribute('position') as BufferAttribute;
    const vertexCount = indices ? indices.count : positions.count;

    // next value for triangle indices
    let nextIndex = 0;

    // attributes and new attribute arrays
    const attributeNames = Object.keys(geometry.attributes);
    const attrArrays: { [index: string]: any[] } = {};
    const morphAttrsArrays: { [index:string]: any[] } = {};
    const newIndices = [];
    // let getters = [ 'getX', 'getY', 'getZ', 'getW' ];

    // initialize the arrays
    for (let i = 0, l = attributeNames.length; i < l; i++) {
      const name = attributeNames[i];

      attrArrays[name] = [];

      const morphAttr = geometry.morphAttributes[name];
      if (morphAttr) {
        morphAttrsArrays[name] = new Array(morphAttr.length).fill(0).map(() => []);
      }
    }

    // convert the error tolerance to an amount of decimal places to truncate to
    const decimalShift = Math.log10(1 / tolerance);
    const shiftMultiplier = Math.pow(10, decimalShift);
    for (let i = 0; i < vertexCount; i++) {
      const index = indices ? indices.getX(i) : i;

      // Generate a hash for the vertex attributes at the current index 'i'
      let hash = '';
      for (let j = 0, l = attributeNames.length; j < l; j++) {
        const name = attributeNames[j];
        const attribute = geometry.getAttribute(name) as BufferAttribute;
        const itemSize = attribute.itemSize;

        const getters = [attribute.getX.bind(attribute), attribute.getY.bind(attribute), attribute.getZ.bind(attribute), attribute.getW.bind(attribute)];

        for (let k = 0; k < itemSize; k++) {
          // double tilde truncates the decimal value
          // attribute.getX(index);
          // hash += `${ ~ ~ ( attribute[ getters[ k ] ]( index ) * shiftMultiplier ) },`;
          // console.log(getters[k], index, attribute.itemSize, attribute.array);
          hash += `${~~(getters[k](index) * shiftMultiplier)},`;
        }
      }

      // console.log(hash);

      // Add another reference to the vertex if it's already
      // used by another index
      if (hash in hashToIndex) {
        newIndices.push(hashToIndex[hash]);
      } else {
        // copy data to the new index in the attribute arrays
        for (let j = 0, l = attributeNames.length; j < l; j++) {
          const name = attributeNames[j];
          const attribute = geometry.getAttribute(name) as BufferAttribute;
          const morphAttr = geometry.morphAttributes[name];
          const itemSize = attribute.itemSize;
          const newarray = attrArrays[name];
          const newMorphArrays = morphAttrsArrays[name];

          const getters = [attribute.getX.bind(attribute), attribute.getY.bind(attribute), attribute.getZ.bind(attribute), attribute.getW.bind(attribute)];

          for (let k = 0; k < itemSize; k++) {
            // let getterFunc = getters[ k ];
            // newarray.push( attribute[ getterFunc ]( index ) );
            newarray.push(getters[k](index));

            if (morphAttr) {
              for (let m = 0, ml = morphAttr.length; m < ml; m++) {
                const mattr = morphAttr[m];
                const mgetters = [mattr.getX.bind(mattr), mattr.getY.bind(mattr), mattr.getZ.bind(mattr), mattr.getW.bind(mattr)];

                // newMorphArrays[ m ].push( morphAttr[ m ][ getterFunc ]( index ) );
                newMorphArrays[m].push(mgetters[k](index));
              }
            }
          }
        }

        hashToIndex[hash] = nextIndex;
        newIndices.push(nextIndex);
        nextIndex++;
      }
    }

    // console.log(vertexCount, Object.keys(hashToIndex).length);

    // Generate typed arrays from new attribute arrays and update
    // the attributeBuffers
    const result = geometry.clone();
    for (let i = 0, l = attributeNames.length; i < l; i++) {
      const name = attributeNames[i];
      const oldAttribute = geometry.getAttribute(name) as BufferAttribute;
      const C = oldAttribute.array.constructor as any;

      const buffer = new C(attrArrays[name]);
      const attribute = new BufferAttribute(buffer, oldAttribute.itemSize, oldAttribute.normalized);

      result.setAttribute(name, attribute);

      // Update the attribute arrays
      if (name in morphAttrsArrays) {
        for (let j = 0; j < morphAttrsArrays[name].length; j++) {
          const oldMorphAttribute = geometry.morphAttributes[name][j] as BufferAttribute;
          const C = oldMorphAttribute.array.constructor as any;
          const buffer = new C(morphAttrsArrays[name][j]);
          const morphAttribute = new BufferAttribute(buffer, oldMorphAttribute.itemSize, oldMorphAttribute.normalized);
          result.morphAttributes[name][j] = morphAttribute;
        }
      }
    }

    // indices

    result.setIndex(newIndices);

    return result;
  },

  getPlaneAxes (plane: Plane): { dx: Vector3; dy: Vector3 } {
    let dx = new Vector3(1, 0, 0);
    const nn = plane.normal.clone().normalize();
    const dot = Math.abs(nn.dot(dx));
    if (dot >= 1.0) {
      dx = new Vector3(0, 1, 0);
    }
    const dy = (new Vector3()).crossVectors(dx, nn).normalize();
    // dx = (new Vector3()).crossVectors(dy, nn).normalize();
    return {
      dx, dy
    };
  },

  normalFrom3Points (a: Vector3, b: Vector3, c: Vector3): Vector3 {
    const bc = (new Vector3()).subVectors(c, b);
    const ba = (new Vector3()).subVectors(a, b);
    return bc.cross(ba).normalize();
  },

  sort (plane: Plane, points: Vector3[]): Vector3[] {
    const { dx, dy } = this.getPlaneAxes(plane);
    const nn = plane.normal.normalize();

    const center = new Vector3();
    points.forEach((p) => {
      center.add(p);
    });
    center.divideScalar(points.length);

    const ox = dx.dot(center);
    const oy = dy.dot(center);

    const projected = points.map((point) => {
      const proj = point.clone().projectOnPlane(nn);

      const x = dx.dot(proj) - ox;
      const y = dy.dot(proj) - oy;
      return {
        point,
        angle: Math.atan2(y, -x)
      };
    });
    const sorted = projected.sort((a, b) => {
      if (a.angle < b.angle) { return -1; } else if (a.angle > b.angle) { return 1; }
      return 0;
    });

    return sorted.map(r => r.point);
  },

  merge (points: Vector3[], threshold: number = 1e-4): Vector3[] {
    const result = [points[0]];
    for (let i = 1, n = points.length; i < n; i++) {
      const last = result[result.length - 1];
      const current = points[i];
      if (last.distanceTo(current) > threshold) {
        result.push(current);
      }
    }
    return result;
  },

  smooth (points: Vector3[], threshold: number = 1.5): Vector3[] {
  // smooth (points: Vector3[], threshold: number = 2.0): Vector3[] {
    while (true) {
      const curvature = this.curvature(points);
      /*
      const indices = [];
      curvature.map((v, i) => {
        if (v > threshold) {
          indices.push(i);
        }
      });
      if (indices.length <= 0) return points;
      for (let i = indices.length - 1; i >= 0; i--) {
        points.splice(i, 1);
      }
      return points;
      */
      const index = curvature.findIndex(v => v > threshold);
      if (index < 0) { return points; }
      points.splice(index, 1);
    }
  },

  curvature (points: Vector3[]): number[] {
    const result: number[] = [];

    for (let i = 0, n = points.length; i < n; i++) {
      const p0 = (i !== 0) ? points[i - 1] : points[n - 1];
      const p1 = points[i];
      const p2 = points[(i + 1) % n];
      const d10 = p1.clone().sub(p0);
      const d21 = p2.clone().sub(p1);
      const angle = d21.normalize().angleTo(d10.normalize());
      result.push(angle);
    }

    return result;
  }

};
