import { Vector3 } from 'three';
import { NurbsCurve } from './NurbsCurve';

export class PolylineCurve {
  points: Vector3[];

  constructor (points: Vector3[]) {
    this.points = points;
  }

  toNurbs (): NurbsCurve {
    return NurbsCurve.byPoints(this.points, 1);
  }
}
