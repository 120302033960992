import { Vector2 } from 'three';

type MetricsProps = {
  ca: number;
  cvai: number;
  ci: number;
  brachycephaly: number;
  L: number;
  W: number;
  circumference: number;
  frontSymmetryRate: number;
  backSymmetryRate: number;
  wholeSymmetryRate: number;
  midPoint?: Vector2;
  section?: SVGGElement;
};

export class Metrics {
  ca: number; //
  cvai: number; //
  ci: number; // 短頭率
  brachycephaly: number; // 短頭率
  L: number; // 前後径
  W: number; // 左右径
  circumference: number; // 頭囲
  frontSymmetryRate: number;
  backSymmetryRate: number;
  wholeSymmetryRate: number;
  svgOffset?: Vector2;
  section?: SVGGElement;

  constructor (props: MetricsProps = {
    ca: 0,
    cvai: 0,
    ci: 0,
    brachycephaly: 0,
    L: 0,
    W: 0,
    circumference: 0,
    frontSymmetryRate: 0,
    backSymmetryRate: 0,
    wholeSymmetryRate: 0,
    midPoint: new Vector2(),
    section: undefined
  }) {
    const {
      ca, cvai, ci,
      brachycephaly, L, W, circumference,
      frontSymmetryRate, backSymmetryRate, wholeSymmetryRate,
      midPoint,
      section
    } = props;
    this.ca = ca;
    this.cvai = cvai;
    this.ci = ci;
    this.brachycephaly = brachycephaly;
    this.L = L;
    this.W = W;
    this.circumference = circumference;
    this.frontSymmetryRate = frontSymmetryRate;
    this.backSymmetryRate = backSymmetryRate;
    this.wholeSymmetryRate = wholeSymmetryRate;
    this.svgOffset = midPoint;
    this.section = section;
  }

  public getTableRows (): string[][] {
    const rows: string[][] = [];

    const front = this.frontSymmetryRate * 100;
    const back = this.backSymmetryRate * 100;

    rows.push(['前後径', `${this.L.toFixed(1)}mm`, '']);
    rows.push(['左右径', `${this.W.toFixed(1)}mm`, '']);
    rows.push(['頭囲', `${this.circumference.toFixed(1)}mm`, '']);
    rows.push(['前頭部 左右対称率', `${front.toFixed(1)}%`, this.getSymmetryRateCriteria(front)]);
    rows.push(['後頭部 左右対称率', `${back.toFixed(1)}%`, this.getSymmetryRateCriteria(back)]);
    rows.push(['短頭率', `${this.brachycephaly.toFixed(1)}%`, this.getCICriteria(this.brachycephaly)]);
    rows.push(['CI', `${this.ci.toFixed(1)}%`, this.getCICriteria(this.ci)]);
    rows.push(['CA (斜頭)', `${this.ca.toFixed(1)}mm`, this.getCACriteria(this.ca)]);
    rows.push(['CVAI (斜頭率)', `${this.cvai.toFixed(1)}%`, this.getCVAICriteria(this.cvai)]);

    return rows;
  }

  // criteria docs:
  // https://docs.google.com/presentation/d/1Ch3Bra4dZGVzzwWTybPXcv-n6UAUsKQlnqv1rIDWCrE/edit#slide=id.gce161fbc70_0_145

  /*
  private getCICriteria(ci: number) {
    if (ci >= 1.0) return '軽度';
    else if (ci > 0.9) return '中程度'
    else if (ci > 0.8) return '重度'
    return '超重度'
  }
  */

  private getCACriteria (ca: number) {
    if (ca < 6.0) { return '正常'; } else if (ca < 9.0) { return '軽度'; } else if (ca < 13.0) { return '中程度'; } else if (ca < 17.0) { return '重度'; }
    return '超重度';
  }

  private getCVAICriteria (cvai: number) {
    if (cvai < 5.0) { return '正常'; } else if (cvai < 7.0) { return '軽度'; } else if (cvai < 10.0) { return '中程度'; } else if (cvai < 14.0) { return '重度'; }
    return '超重度';
  }

  private getCICriteria (ci: number) {
    if (ci < 94.0) { return '正常'; } else if (ci < 101.0) { return '軽度'; }
    return '重度';
  }

  private getSymmetryRateCriteria (rate: number) {
    if (rate >= 90) { return '軽度'; } else if (rate >= 85) { return '中程度'; } else if (rate >= 80) {
      return '重度';
    } else {
      return '超重度';
    }
  }
}
