import { Vector3 } from 'three';
import verb from '../lib/verb/verb';

export class NurbsCurve {
  private verb: any;

  constructor (verb: any) {
    this.verb = verb;
  }

  public getVerb (): any {
    return this.verb;
  }

  public getPointAt (u: number): Vector3 {
    const arr = this.verb.point(u);
    return new Vector3(arr[0], arr[1], arr[2]);
  }

  public getTangentAt (u: number): Vector3 {
    const ret = this.verb.tangent(u);
    return (new Vector3(ret[0], ret[1], ret[2])).normalize();
  }

  public getClosestParam (p: Vector3): number | undefined {
    const t = this.verb.closestParam([p.x, p.y, p.z]);
    if (isNaN(t)) { return undefined; }
    return t as number;
  }

  public length (): number {
    return this.verb.length();
  }

  public divideByArcLength (unit: number): number[] {
    return this.verb.divideByArcLength(unit).map((u: any) => u.u as number);
  }

  public static byPoints (points: Vector3[], degree: number): NurbsCurve {
    const inputs = points.map(p => [p.x, p.y, p.z]);
    const curve = verb.geom.NurbsCurve.byPoints(inputs, degree);
    return new NurbsCurve(curve);
  }
}
