
export class Face {
  a: number;
  b: number;
  c: number;

  constructor (a: number, b: number, c: number) {
    this.a = a;
    this.b = b;
    this.c = c;
  }
}
