import { ContourResult } from "@/geometry/Contour";
import { Layer } from "@/geometry/Layer";
import { Anchor } from "./Anchor";
import { Metrics } from "./Metrics";

export class MetricsGenerator {

  constructor () {
  }

  public generate(contour: ContourResult, anchor: Anchor): Metrics {
    const baseLayer = this.getBaseLayer(contour.layers);
    return this.computeMetrics(anchor, contour.layers, contour.step, baseLayer);
  }

  private getBaseLayer (layers: Layer[]) {
    const baseIndex = Math.floor(layers.length * 0.3);
    return layers[baseIndex];
  }

  private computeMetrics (anchor: Anchor, layers: Layer[], step: number, baseLayer: Layer, options: { strokeColor?: string; } = {
    strokeColor: '#33aafa'
  }) {
    const { midPoint } = baseLayer.getAxes(anchor);
    const ca = baseLayer.ca(anchor);
    const ci = baseLayer.ci(anchor);
    const { element: svg, offset } = baseLayer.toSVG(anchor, options);
    const rate = this.computeSymmetryRate(anchor, layers);
    const metrics = new Metrics({
      ca: ca.ca,
      cvai: ca.cvai,
      ci: ci.ci,
      brachycephaly: ci.brachycephaly,
      L: ci.L,
      W: ci.W,
      circumference: baseLayer.length(),
      frontSymmetryRate: rate.frontSymmetryRate,
      backSymmetryRate: rate.backSymmetryRate,
      wholeSymmetryRate: rate.wholeSymmetryRate,
      midPoint: midPoint.sub(offset),
      section: svg
    });
    return metrics;
  }

  protected computeSymmetryRate (anchor: Anchor, layers: Layer[]): {
    frontSymmetryRate: number;
    backSymmetryRate: number;
    wholeSymmetryRate: number;
  } {
    // https://docs.google.com/presentation/d/1Ch3Bra4dZGVzzwWTybPXcv-n6UAUsKQlnqv1rIDWCrE/edit#slide=id.gce161fbc70_0_53
    // > 左右対称率は左右の体積が大きい方を100%とし、
    // > 少ない方のパーセンテージを求め、前頭部、後頭部を個別に体積比率(対称率)を求める。

    let rt = 0;
    let rb = 0;
    let lb = 0;
    let lt = 0;

    for (let i = 0; i < layers.length; i++) {
      const layer = layers[i];
      try {
        const { area } = layer.divide(anchor);
        rt += area.rt;
        rb += area.rb;
        lb += area.lb;
        lt += area.lt;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`divide error: ${i}`);
      }
    }

    const frontSymmetryRate = lt > rt ? (rt / lt) : (lt / rt);
    const backSymmetryRate = lb > rb ? (rb / lb) : (lb / rb);

    const l = lt + rt;
    const r = rt + rb;
    const wholeSymmetryRate = l > r ? (r / l) : (l / r);
    return {
      frontSymmetryRate, backSymmetryRate, wholeSymmetryRate
    };
  }


}