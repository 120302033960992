
import { Vector2 } from 'three';

export class Line2D {
  a: number;
  b: number;
  c: number;

  constructor (a: number = 0, b: number = 0, c: number = 0) {
    this.a = a;
    this.b = b;
    this.c = c;
  }

  public setFromPoints (p0: Vector2, p1: Vector2): Line2D {
    const dx = p1.x - p0.x;
    const dy = p1.y - p0.y;
    this.a = dy;
    this.b = -dx;
    this.c = dx * p0.y - dy * p0.x;
    return this;
  }

  public getIntersectionPoint (l: Line2D): Vector2 | null {
    const d = this.a * l.b - l.a * this.b;
    if (d === 0.0) {
      return null;
    }

    const x = (this.b * l.c - l.b * this.c) / d;
    const y = (l.a * this.c - this.a * l.c) / d;
    return new Vector2(x, y);
  }

  public getPoint (x: number): number {
    return this.a * x + this.b;
  }
}
