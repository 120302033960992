import { Anchor } from "@/misc/Anchor";
import { BufferAttribute, BufferGeometry, Vector3 } from "three";
import { Contour, ContourResult } from "./Contour";
import { NPlane } from "./NPlane";

export class ContourGenerator {

  constructor () {
  }

  public generate (geometry: BufferGeometry, anchor: Anchor): ContourResult {
    const { head, ear } = this.computeEarClipped(geometry, anchor);

    /*
    if (this._debug !== undefined) {
      this._scene.remove(this._debug);
    }
    this._debug = new Mesh(head, new MeshNormalMaterial({}));
    this._scene.add(this._debug);
    */

    const contour = new Contour();
    return contour.process(geometry, anchor.plane);
  }

  protected computeEarClipped (source: BufferGeometry, anchor: Anchor, radius: number = 25): {
    head: BufferGeometry;
    ear: BufferGeometry;
  } {
    const inPositionAttrib = (source.getAttribute('position') as BufferAttribute).array;
    const inNormalAttrib = (source.getAttribute('normal') as BufferAttribute).array;

    const inVertices: Vector3[] = [];
    const inNormals: Vector3[] = [];
    for (let i = 0; i < inPositionAttrib.length; i += 3) {
      inVertices.push(new Vector3(inPositionAttrib[i], inPositionAttrib[i + 1], inPositionAttrib[i + 2]));
      inNormals.push(new Vector3(inNormalAttrib[i], inNormalAttrib[i + 1], inNormalAttrib[i + 2]));
    }

    const {
      in: {
        vertices: hVertices,
        normals: hNormals
      },
      out: {
        vertices: eVertices,
        normals: eNormals
      }
    } = this.divideEarClipped(inVertices, inNormals, anchor, radius);

    const hPositionAttrib = new Float32Array(hVertices.length * 3);
    const hNormalAttrib = new Float32Array(hNormals.length * 3);
    const ePositionAttrib = new Float32Array(eVertices.length * 3);
    const eNormalAttrib = new Float32Array(eNormals.length * 3);

    hVertices.forEach((v, idx) => {
      const i = idx * 3;
      hPositionAttrib[i] = v.x;
      hPositionAttrib[i + 1] = v.y;
      hPositionAttrib[i + 2] = v.z;

      const n = hNormals[idx];
      hNormalAttrib[i] = n.x;
      hNormalAttrib[i + 1] = n.y;
      hNormalAttrib[i + 2] = n.z;
    });

    eVertices.forEach((v, idx) => {
      const i = idx * 3;
      ePositionAttrib[i] = v.x;
      ePositionAttrib[i + 1] = v.y;
      ePositionAttrib[i + 2] = v.z;

      const n = eNormals[idx];
      eNormalAttrib[i] = n.x;
      eNormalAttrib[i + 1] = n.y;
      eNormalAttrib[i + 2] = n.z;
    });

    const head = new BufferGeometry();
    head.setAttribute('position', new BufferAttribute(hPositionAttrib, 3));
    head.setAttribute('normal', new BufferAttribute(hNormalAttrib, 3));
    head.computeBoundingBox();

    const ear = new BufferGeometry();
    ear.setAttribute('position', new BufferAttribute(ePositionAttrib, 3));
    ear.setAttribute('normal', new BufferAttribute(eNormalAttrib, 3));
    ear.computeBoundingBox();

    return {
      head, ear
    };
  }

  protected divideEarClipped (inVertices: Vector3[], inNormals: Vector3[], anchor: Anchor, radius: number = 25) {
    const axis = anchor.leftEarPoint.clone().sub(anchor.rightEarPoint).normalize();
    const plane = NPlane.fromOriginNormal(anchor.leftEarPoint.clone(), axis);
    const center = plane.project(anchor.leftEarPoint.clone());

    const threshold = radius * radius;
    const hVertices: Vector3[] = [];
    const hNormals: Vector3[] = [];
    const eVertices: Vector3[] = [];
    const eNormals: Vector3[] = [];

    for (let i = 0; i < inVertices.length; i += 3) {
      const p0 = inVertices[i];
      const p1 = inVertices[i + 1];
      const p2 = inVertices[i + 2];
      const v0 = plane.project(p0).distanceToSquared(center);
      const v1 = plane.project(p1).distanceToSquared(center);
      const v2 = plane.project(p2).distanceToSquared(center);
      const n0 = inNormals[i];
      const n1 = inNormals[i + 1];
      const n2 = inNormals[i + 2];

      if (v0 > threshold && v1 > threshold && v2 > threshold) {
        hVertices.push(p0);
        hVertices.push(p1);
        hVertices.push(p2);
        hNormals.push(n0);
        hNormals.push(n1);
        hNormals.push(n2);
      } else {
        eVertices.push(p0);
        eVertices.push(p1);
        eVertices.push(p2);
        eNormals.push(n0);
        eNormals.push(n1);
        eNormals.push(n2);
      }
    }

    return {
      in: {
        vertices: hVertices,
        normals: hNormals
      },
      out: {
        vertices: eVertices,
        normals: eNormals
      }
    };
  }

}