import { Mesh, OrthographicCamera, Renderer, Scene } from "troisjs";
import { Ref } from "vue";
import { createMachine } from "xstate";
import { raise, send } from "xstate/lib/actions";

import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { Anchor } from "@/misc/Anchor";
import { ReferencePoint } from "@/misc/ReferencePoint";
import { ContourResult } from "@/geometry/Contour";
import { ContourGenerator } from "@/geometry/ContourGenerator";
import { BufferGeometry, Color, Raycaster, Vector2, Vector3, WebGLRenderer } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { Metrics } from "@/misc/Metrics";
import { MetricsGenerator } from "@/misc/MetricsGenerator";

interface ViewerStateMachineProps {
  renderer: Ref<typeof Renderer | null>;
  camera: Ref<typeof OrthographicCamera | null>;
  mesh: Ref<typeof Mesh | null>;
  scene: Ref<typeof Scene | null>;

  transform?: TransformControls;
  referencePoint?: ReferencePoint;
  anchor?: Anchor;

  onChangeTransform: () => void;
}

interface ViewerStateMachineContext extends ViewerStateMachineProps {
  metrics: {
    geometry: BufferGeometry;
    origin: Vector3;
    prev: Vector2;
    index: number;
    line: Line2 | null;
    contour: ContourResult | null;
    metrics: Metrics | null;
  }
}

const metricStates = {
  initial: 'idle',
  states: {
    idle: {
      on: {
        mousedown: {
          target: 'drag',
          cond: (context: ViewerStateMachineContext) => {
            const { metrics } = context;
            return metrics.index >= 0;
          }
        },
        mousemove: {
          actions: ['metricsIdleMouseMove']
        },
        resetAnchor: {
          actions: ['metricsIdleResetAnchor', 'updateContour']
        }
      },
      entry: [
      ]
    },
    drag: {
      on: {
        mouseup: { target: 'idle' },
        mousemove: {
          actions: ['metricsDragMouseMove', 'updateContour']
        },
      },
      entry: [
        'activateMetricsDrag'
      ],
      exit: [
        'deactivateMetricsDrag'
      ]
    }
  }
};

export const createViewerStateMachine = (context: ViewerStateMachineProps) => {
  return createMachine({
    id: 'viewer',
    context: {
      ...context,
      metrics: {
        geometry: new BufferGeometry(),
        origin: new Vector3(),
        prev: new Vector2(),
        index: -1,
        line: null,
        contour: null,
        metrics: null,
      }
    },
    initial: 'default',
    states: {
      default: {
        on: {
          transform: 'transform',
          metrics: 'metrics',
        }
      },
      transform: {
        on: {
          default: 'default',
          metrics: 'metrics',
        },
        entry: [
          'activateTransform'
        ],
        exit: [
          'deactivateTransform'
        ]
      },
      metrics: {
        on: {
          default: 'default',
          transform: 'transform',
        },
        entry: [
          'activateMetrics', 'updateContour'
        ],
        exit: [
          'deactivateMetrics'
        ],
        ...metricStates
      }
    },
  },
    {
      actions: {
        activateTransform: (context, event) => {
          const { renderer, camera, scene, mesh, onChangeTransform } = context;
          const orbitCtrl = renderer.value?.three.cameraCtrl;
          const transformCtrl = new TransformControls(camera.value!.camera, renderer.value!.canvas);
          transformCtrl.setSize(1.25);
          transformCtrl.setMode('rotate');
          transformCtrl.setSpace('world');
          transformCtrl.attach(mesh.value!.mesh);
          transformCtrl.addEventListener('dragging-changed', (e) => {
            orbitCtrl.enabled = !e.value;
          });
          transformCtrl.addEventListener('rotationAngle-changed', (e) => {
            onChangeTransform();
          });
          // transformCtrl.addEventListener('objectChange', (e) => {});
          scene.value!.scene.add(transformCtrl);
          orbitCtrl.enablePan = false;

          context.transform = transformCtrl;
        },
        deactivateTransform: (context, event) => {
          const { transform, renderer, scene, mesh } = context;
          if (transform !== undefined) {
            const orbitCtrl = renderer.value?.three.cameraCtrl;
            scene.value!.scene.remove(transform);
            transform.detach();
            // mesh.value!.?.fix();
            orbitCtrl.enablePan = true;
          }
        },
        activateMetrics: (context, event) => {
          const { renderer, scene, anchor, mesh, metrics } = context;

          const m = mesh.value!;
          const { geometry, matrix } = m.mesh;
          const g = geometry.clone();
          g.applyMatrix4(matrix);
          g.computeBoundingBox();
          metrics.geometry = g;

          if (anchor === undefined) {
            const anchor = createAnchor(metrics.geometry, renderer.value!.renderer);
            scene.value!.scene.add(anchor);
            context.anchor = anchor;
          } else {
            anchor.visible = true;
          }
        },
        deactivateMetrics: (context: ViewerStateMachineContext, event) => {
          const { anchor, metrics } = context;
          if (anchor !== undefined) {
            anchor.visible = false;
          }
          if (metrics.line !== null) {
            metrics.line.visible = false;
          }
        },
        metricsIdleMouseDown: (context, event, p) => {
          const result = intersects(context, event as any);
          if (result !== undefined) {
            console.log(context, event, p);
          }
        },
        metricsIdleMouseMove: (context, event) => {
          const { anchor } = context;
          const result = intersects(context, event as any);
          if (result !== undefined) {
            anchor!.highlight(result);
            context.metrics.index = anchor?.index(result) ?? -1;

            const { mouse } = event;
            const { clientX, clientY } = mouse;
            const screen = new Vector2(clientX, clientY);
            context.metrics.prev = screen;
          } else {
            anchor?.unhighlight();
            context.metrics.index = -1;
          }
        },
        metricsIdleResetAnchor: (context, event) => {
          const { renderer, scene, anchor, metrics } = context;
          if (anchor !== undefined) {
            scene.value!.scene.remove(anchor);
          }
          const newAnchor = createAnchor(metrics.geometry, renderer.value!.renderer);
          scene.value!.scene.add(newAnchor);
          context.anchor = newAnchor;
        },
        activateMetricsDrag: (context, event) => {
          const { renderer } = context;
          const orbitCtrl = renderer.value?.three.cameraCtrl;
          orbitCtrl.enabled = false;
          const offsets = context.anchor?.getOffsetPoints() as Vector3[];
          context.metrics.origin = offsets[context.metrics.index];
        },
        deactivateMetricsDrag: (context) => {
          const { renderer, metrics } = context;
          const orbitCtrl = renderer.value?.three.cameraCtrl;
          orbitCtrl.enabled = true;
          metrics.index = -1;
        },
        metricsDragMouseMove: (context, event) => {
          const { renderer, camera, mesh, anchor, metrics } = context;
          const { mouse } = event;
          const { clientX, clientY } = mouse;
          const input = new Vector2(clientX, clientY);

          const offsets = anchor!.getOffsetPoints() as Vector3[];
          let o = offsets[metrics.index];
          const delta = input.clone().sub(metrics.prev);

          const { width, height } = renderer.value!.canvas.getBoundingClientRect();

          const proj = metrics.origin.clone().project(camera.value!.camera);
          proj.x += (delta.x / width) * 2;
          proj.y -= (delta.y / height) * 2;
          o = proj.unproject(camera.value!.camera);

          const raycaster = new Raycaster(o, anchor!.directions[metrics.index].clone().multiplyScalar(-1));
          const intersections = raycaster.intersectObject(mesh.value!.mesh);

          if (intersections.length > 0) {
            const distances = intersections.map(i => i.distance);
            const min = Math.min(...distances);
            const i = distances.indexOf(min);
            const closest = intersections[i];
            anchor?.set(closest.point, metrics.index);
            anchor?.update();
          }
        },
        updateContour: (context: ViewerStateMachineContext, event) => {
          const { renderer, scene, mesh, anchor, metrics } = context;

          if (metrics.line !== null) {
            scene.value!.scene.remove(metrics.line);
            metrics.line = null;
          }

          if (anchor !== undefined) {
            const gen = new ContourGenerator();
            const contour = gen.generate(metrics.geometry, anchor);
            const { layers } = contour;
            if (layers.length > 0) {
              const baseIndex = Math.floor(layers.length * 0.3);
              const layer = layers[baseIndex];
              const geom = layer.toGeometry();
              const resolution = new Vector2();
              renderer.value!.renderer.getSize(resolution);
              const line = new Line2(geom, new LineMaterial({
                color: new Color(0x33AAFA).getHex(),
                linewidth: 2,
                resolution,
                polygonOffset: true,
                polygonOffsetFactor: -1,
                polygonOffsetUnits: 1
              }));
              line.computeLineDistances();
              metrics.line = line;
              scene.value!.scene.add(line);

              metrics.metrics = new MetricsGenerator().generate(contour, anchor);
            }
          }
        },
      }
    })
}

const intersects = (context: ViewerStateMachineContext, event: { mouse: MouseEvent }): Vector3 | undefined => {
  const { renderer, anchor, camera } = context;
  const { mouse } = event;
  const { x, y, width, height } = renderer.value!.canvas.getBoundingClientRect();
  const { clientX, clientY } = mouse;
  const screen = new Vector2(clientX, clientY);
  return anchor!.intersects(camera.value!.camera, new Vector2(width, height), screen.sub(new Vector2(x, y)));
};

const createAnchor = (geometry: BufferGeometry, renderer: WebGLRenderer) => {
  const box = geometry.boundingBox!;
  const size = new Vector3();
  const center = new Vector3();
  box.getSize(size);
  box.getCenter(center);
  const nosePoint = new Vector3(0, 0, size.z * 0.5).add(center);
  const rightEarPoint = new Vector3(-size.x * 0.5 - 5, -5, 0).add(center);
  const leftEarPoint = new Vector3(size.x * 0.5 + 5, -5, 0).add(center);
  const anchor = new Anchor(nosePoint, rightEarPoint, leftEarPoint);
  const resolution = new Vector2();
  renderer.getSize(resolution);
  anchor.setResolution(resolution);
  anchor.update();
  return anchor;
};
