

import { useMachine } from '@xstate/vue';
import { createViewerStateMachine } from '@/states/ViewerStateMachine';
import { ref, defineComponent, reactive, onMounted, watch, toRefs } from 'vue';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { Mesh, Scene, DirectionalLight, OrthographicCamera, Renderer } from 'troisjs';
import { BufferGeometry, OrthographicCamera as OC, Mesh as M, Euler, Vector3 } from 'three';
import WorldAxis from "./WorldAxis.vue";
import TransformForm from "./TransformForm.vue";
import MetricsViewer from "./MetricsViewer.vue";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const readFileArrayBufferAsync = (file: Blob): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const readFileStringAsync = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

export default defineComponent({
  name: "ModelViewer",
  components: {
    TransformForm,
    MetricsViewer,
    WorldAxis
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    file: {
      type: File,
      required: true
    },
    syncCamera: {
      type: Boolean,
      required: true,
      default: false,
    },
    syncedCameraTransform: {
      type: Object,
      required: true,
      default: null,
    }
  },
  emits: [
    "update:cameraTransform"
  ],
  setup(props, { emit }) {
    const root = ref<HTMLDivElement | null>(null);
    const renderer = ref<typeof Renderer | null>(null);
    const camera = ref<typeof OrthographicCamera | null>(null);
    const mesh = ref<typeof Mesh | null>(null);
    const scene = ref<typeof Scene | null>(null);
    const axis = ref<typeof WorldAxis | null>(null);
    const transformForm = ref<typeof TransformForm | null>(null);

    const { state: machine, send } = useMachine(createViewerStateMachine({
      renderer,
      camera,
      scene,
      mesh,
      onChangeTransform: () => {
        const { value } = transformForm;
        if (value !== null) {
          value.$forceUpdate();
        }
      }
    }));

    // console.log(machine);

    const state = reactive<{
      mode: string;
      light: Vector3;
      orthographic: {
        left: number;
        right: number;
        top: number;
        bottom: number;
      },
      geometry: BufferGeometry | null;
      rotation: Euler;
    }>({
      mode: 'default',
      light: new Vector3(0, 50, 50),
      orthographic: {
        left: -1,
        right: 1,
        bottom: -1,
        top: 1,
      },
      geometry: null,
      rotation: new Euler(),
    });

    const { syncCamera, syncedCameraTransform } = toRefs(props);
    let dragging = false;

    watch(syncCamera, (current) => {
    });

    watch(syncedCameraTransform, (current) => {
      if (!dragging && syncCamera.value) {
        const orbitCtrl: OrbitControls = renderer.value?.three.cameraCtrl;
        const { position, target, zoom } = current;
        const c: OC = camera.value!.camera;
        c.position.copy(position);
        c.zoom = zoom;
        c.updateProjectionMatrix();
        // orbitCtrl.position0.copy(position);
        // orbitCtrl.target0.copy(target);
        orbitCtrl.target.copy(target);
        // orbitCtrl.zoomO = zoom;
        // orbitCtrl.reset();
      }
    });

    watch(() => state.mode, (current) => {
      send(current);
    });

    const observer = new ResizeObserver((e) => {
      const rect = root.value?.getBoundingClientRect();
      if (rect !== undefined) {
        const { width, height } = rect;
        state.orthographic.left = -width / 2.0;
        state.orthographic.right = width / 2.0;
        state.orthographic.top = height / 2.0;
        state.orthographic.bottom = -height /2.0;
        renderer.value!.three.setSize(width, height)
      }
    });

    onMounted(async () => {
      if (root.value !== null) {
        observer.observe(root.value);
      }

      const c = camera.value!.camera;
      axis.value?.update(c);

      const orbitCtrl: OrbitControls = renderer.value?.three.cameraCtrl;

      orbitCtrl.addEventListener('start', (e) => {
        dragging = true;
      });

      orbitCtrl.addEventListener('change', () => {
        const c = camera.value!.camera;
        axis.value?.update(c);
        const { object } = orbitCtrl;
        const { position } = object;
        state.light.copy(position);

        if (dragging) {
          emit("update:cameraTransform", {
            position: c.position,
            target: orbitCtrl.target,
            zoom: c.zoom
          });
        }
      });

      orbitCtrl.addEventListener('end', (e) => {
        dragging = false;
      });

      renderer.value?.onBeforeRender(() => {
      });

      const { file } = props;
      const { name } = file;
      if (name.toLowerCase().endsWith('.stl')) {
        const data = await readFileArrayBufferAsync(file);
        const loader = new STLLoader();
        const geometry = loader.parse(data);
        geometry.computeBoundingBox();
        mesh.value?.setGeometry(geometry);
        state.geometry = geometry.clone();
      } else if (name.toLowerCase().endsWith('.obj')) {
        const data = await readFileStringAsync(file);
        const loader = new OBJLoader();
        const group = loader.parse(data);
        const m = group.children.find(o => o instanceof M);
        if (m !== undefined) {
          const { geometry } = m as M;
          geometry.computeBoundingBox();
          mesh.value?.setGeometry(geometry);
          state.geometry = geometry.clone();
        }
      }

      state.rotation = mesh.value!.mesh.rotation;
    });

    const getCameraPosition = (origin: Vector3, direction: Vector3): Vector3 => {
      const distance = camera.value!.camera.position.distanceTo(origin);
      const to = origin.clone().add(direction.clone().multiplyScalar(distance));
      return to;
    }

    const alignAxis = (direction: Vector3) => {
      const orbitCtrl: OrbitControls = renderer.value!.three.cameraCtrl
      // const { target0 } = orbitCtrl;
      const target0 = new Vector3();
      const to = getCameraPosition(target0, direction);

      const c = camera.value!.camera;
      c.position.copy(to);
      c.lookAt(target0);

      orbitCtrl.position0.copy(to);
      orbitCtrl.target.copy(target0);
      orbitCtrl.reset();

      emit("update:cameraTransform", {
        position: c.position,
        target: orbitCtrl.target,
        zoom: c.zoom
      });
    };

    return {
      machine,
      state,
      root,
      renderer,
      scene,
      camera,
      mesh,
      axis,
      transformForm,
      alignAxis,
      mouseDown: (e: MouseEvent) => {
        send('mousedown', {
          mouse: e
        });
      },
      mouseMove: (e: MouseEvent) => {
        send('mousemove', {
          mouse: e
        });
      },
      mouseUp: (e: MouseEvent) => {
        send('mouseup', {
          mouse: e
        });
      },
      updateRotation: (rot: Euler) => {
      },
      resetAnchor: () => {
        send('resetAnchor', {});
      },
    };
  }
});

