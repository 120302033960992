
import { ref, defineComponent, reactive } from 'vue';

export default defineComponent({
  name: "FileDrop",
  props: {
    dialog: {
      type: Boolean,
      require: true,
    }
  },
  emits: [
    "update:dialog",
    "submit:files"
  ],
  setup (props, { emit }) {
    const fileInput = ref<HTMLInputElement | null>(null);

    const state = reactive<{
      files: File[];
      dragOver: boolean;
    }>({
      files: [],
      dragOver: false
    });

    const onSelect = () => {
      fileInput.value?.click();
    };

    const upload = (fileList: FileList) => {
      for (let i = 0; i < fileList.length; i++) {
        const f = fileList[i];
        const { name } = f;
        const n = name.toLowerCase();
        if (n.endsWith('.stl') || n.endsWith('.obj')) {
          state.files.push(f);
        }
      }
    };

    const removeFile = (fileName: string) => {
      const index = state.files.findIndex(
        file => file.name === fileName
      );
      if (index > -1) { state.files.splice(index, 1); }
    };

    const onChange = (e: Event) => {
      const { target } = e;
      const input = target as HTMLInputElement;
      const { files } = input;
      if (files !== null) {
        upload(files);
      }
    };

    const onDrop = (e: DragEvent) => {
      state.dragOver = false;
      const { dataTransfer } = e;
      if (dataTransfer !== null) {
        const { files } = dataTransfer;
        upload(files);
      }
    };

    const close = () => {
      emit('update:dialog', false);
    };

    const submit = () => {
      emit('submit:files', state.files);
      close();
    };

    return {
      state,
      fileInput,
      onSelect,
      onChange,
      onDrop,
      removeFile,
      close,
      submit
    };
  }
});

