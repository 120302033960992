import { Vector3 } from 'three';

export class CubicHermiteCurve {
  private startPoint: Vector3;
  private startTangent: Vector3;
  private endPoint: Vector3;
  private endTangent: Vector3;

  constructor (startPoint: Vector3, startTangent: Vector3, endPoint: Vector3, endTangent: Vector3) {
    this.startPoint = startPoint;
    this.startTangent = startTangent;
    this.endPoint = endPoint;
    this.endTangent = endTangent;
  }

  public getPointAt (t: number): Vector3 {
    // https://en.wikipedia.org/wiki/Cubic_Hermite_spline
    const t2 = t * t;
    const t3 = t2 * t;
    const _2t3 = 2.0 * t3;
    const _3t2 = 3.0 * t2;
    const a = _2t3 - _3t2 + 1.0;
    const b = -_2t3 + _3t2;
    const c = t3 - 2.0 * t2 + t;
    const d = t3 - t2;
    return this.startPoint.clone().multiplyScalar(a)
      .add(this.endPoint.clone().multiplyScalar(b))
      .add(this.startTangent.clone().multiplyScalar(c))
      .add(this.endTangent.clone().multiplyScalar(d));
  }
}
