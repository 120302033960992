import { Box3, BufferAttribute, BufferGeometry, BufferGeometryUtils, Line3, Plane, Vector3 } from 'three';
import { Face } from './Face';
import GeometryUtils from './GeometryUtils';
import { Layer } from './Layer';

class MeshEdge {
  start: Vector3;
  end: Vector3;
  faces: Face[];

  constructor (start: Vector3, end: Vector3, face: Face) {
    this.start = start;
    this.end = end;
    this.faces = [face];
  }

  // add a face which has this edge
  public addFace (face: Face): void {
    this.faces.push(face);
  }

  public isNeighbor (other: MeshEdge): boolean {
    const found = this.faces.find(face => other.faces.includes(face));
    return found !== undefined;
  }
}

class Intersection {
  point: Vector3;
  edge: MeshEdge;

  constructor (point: Vector3, edge: MeshEdge) {
    this.point = point;
    this.edge = edge;
  }
}

export type ContourResult = {
  origin: Vector3;
  axis: Vector3;
  layers: Layer[];
  step: number;
};

export class Contour {
  // get planes aligned bounding box walls
  private getBoundingPlanes (bb: Box3): Plane[] {
    const min = bb.min;
    const max = bb.max;
    const center = new Vector3();
    bb.getCenter(center);

    return [
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(-1, 0, 0), new Vector3(min.x, center.y, center.z)),
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(1, 0, 0), new Vector3(max.x, center.y, center.z)),
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(0, -1, 0), new Vector3(center.x, min.y, center.z)),
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(0, 1, 0), new Vector3(center.x, max.y, center.z)),
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(0, 0, -1), new Vector3(center.x, center.y, min.z)),
      new Plane().setFromNormalAndCoplanarPoint(new Vector3(0, 0, 1), new Vector3(center.x, center.y, max.z))
    ];
  }

  private findDuplicatedIntersection (point: Vector3, intersections: Intersection[]): Intersection {
    const found = intersections.find(other => other.point.distanceToSquared(point) < Number.EPSILON);
    return found as Intersection;
  }

  private findNeighborIntersection (target: Intersection, intersections: Intersection[]): number {
    if (intersections.length <= 0) {
      return -1;
    }

    const idx = intersections.findIndex((other) => {
      return other.edge.isNeighbor(target.edge);
    });
    if (idx >= 0) {
      return idx;
    }

    return -1;
  }

  private groupIntersections (intersections: Intersection[]): Intersection[][] {
    const groups: Intersection[][] = [];
    while (intersections.length > 0) {
      const group = this.sortIntersections(intersections);
      groups.push(group);
    }

    return groups;
  }

  private sortIntersections (intersections: Intersection[]): Intersection[] {
    if (intersections.length <= 0) {
      return [];
    }

    // Pop start position
    let current = intersections.pop() as Intersection;
    const sorted = [current];

    while (intersections.length > 0) {
      const idx = this.findNeighborIntersection(current, intersections);
      if (idx >= 0) {
        current = intersections[idx] as Intersection; // next intersection
        sorted.push(current);
        intersections.splice(idx, 1);
      } else {
        // console.warn('neighbor intersection not found')
        // return []
        break;
      }
    }

    return sorted;
  }

  // Get intersection points between bounding box of geometry & direction vector
  public getCorners (geometry: BufferGeometry, direction: Vector3): { point: Vector3; dot: number }[] {
    let bb = geometry.boundingBox;
    if (bb === null) {
      geometry.computeBoundingBox();
      bb = geometry.boundingBox as Box3;
    }
    const planes = this.getBoundingPlanes(bb);
    const center = new Vector3();
    const size = new Vector3();
    bb.getCenter(center);
    bb.getSize(size);

    const dline = new Line3(center, center.clone().add(direction.clone().multiplyScalar(100000)));
    const dline2 = new Line3(center, center.clone().add(direction.clone().multiplyScalar(-100000)));
    const length = Math.abs(size.dot(direction));

    const intersections: Vector3[] = [];
    for (let i = 0, n = planes.length; i < n; i++) {
      let target = new Vector3();
      const intersection = planes[i].intersectLine(dline, target);
      if (
        intersection !== null && intersection.distanceTo(dline.start) <= length
      ) {
        intersections.push(intersection.clone());
      }

      target = new Vector3();
      const intersection2 = planes[i].intersectLine(dline2, target);
      if (
        intersection2 !== null &&
        intersection2.distanceTo(dline.start) <= length
      ) {
        intersections.push(intersection2.clone());
      }
    }

    const corners = intersections.map((intersection) => {
      const sub = (new Vector3()).subVectors(intersection, center);
      return {
        point: intersection,
        dot: sub.dot(direction)
      };
    });

    return corners.sort((c0, c1) => {
      if (c0.dot < c1.dot) {
        return -1;
      } else if (c0.dot === c1.dot) {
        return 0;
      }
      return 1;
    });
  }

  process (geometry: BufferGeometry, plane: Plane, division: number = 30): ContourResult {
    const position = (geometry.getAttribute('position') as BufferAttribute).array;
    const vertices: Vector3[] = [];
    for (let i = 0; i < position.length; i += 3) {
      vertices.push(new Vector3(position[i], position[i + 1], position[i + 2]));
      // console.log(vertices[vertices.length - 1]);
    }

    const faces: Face[] = [];
    if (geometry.index === null) {
      const n = position.length / 3;
      for (let i = 0; i < n; i += 3) {
        faces.push(new Face(i, i + 1, i + 2));
      }
    } else {
      const index = (geometry.index as BufferAttribute).array;
      for (let i = 0; i < index.length; i += 3) {
        faces.push(new Face(index[i], index[i + 1], index[i + 2]));
      }
    }
    // console.log(vertices.length, vertices.length / 3, faces.length);
    // this.check(faces);

    const direction = plane.normal.normalize();
    const origin = new Vector3();
    plane.coplanarPoint(origin);
    // const origin = direction.clone().multiplyScalar(plane.constant);

    const center = new Vector3();
    if (geometry.boundingBox === null) { geometry.computeBoundingBox(); }
    (geometry.boundingBox as Box3).getCenter(center);
    const corners = this.getCorners(geometry, direction);

    if (corners.length < 2) {
      throw new Error('Cannot find an intersection point between axis line & input geometry');
    }

    // return corners.map(c => c.point);

    const end = corners[corners.length - 1].point.clone();
    const axis: Vector3 = (new Vector3()).subVectors(end, origin);

    const dict: { [index:string]: MeshEdge } = {};
    const edges: MeshEdge[] = [];

    const addEdge = (i0: number, i1: number, face: Face) => {
      let a, b;
      if (i0 <= i1) {
        a = i0;
        b = i1;
      } else {
        a = i1;
        b = i0;
      }
      const key = `${a}-${b}`;
      if (!dict[key]) {
        const pa = vertices[a];
        const pb = vertices[b];

        const edge = new MeshEdge(pa, pb, face);
        edges.push(edge);
        dict[key] = edge;
      } else {
        dict[key].addFace(face);
      }
    };

    faces.forEach((face) => {
      addEdge(face.a, face.b, face);
      addEdge(face.b, face.c, face);
      addEdge(face.c, face.a, face);
    });
    const ln = edges.length;

    /*
    return new Array(count).fill(0).map((_, i) => {
      return start.clone().add(direction.clone().multiplyScalar(i * step));
    });
    */

    const layers: Layer[] = [];

    const step = axis.length() / division;
    for (let i = 0; i < division; i++) {
      // const origin = start.clone().add(direction.clone().multiplyScalar(i * step));
      const center = origin.clone().add(direction.clone().multiplyScalar(i * step));
      const dplane = new Plane().setFromNormalAndCoplanarPoint(direction, center);

      const intersections: Intersection[] = [];

      for (let j = 0; j < ln; j++) {
        // Planeと交差したEdgeと点位置を保持
        const t = new Vector3();
        const v = dplane.intersectLine(new Line3(edges[j].start, edges[j].end), t);

        if (v !== null) {
          // points.push(v);
          intersections.push(new Intersection(v, edges[j]));
        }
      }

      if (intersections.length <= 0) { continue; }

      // MEMO: Modify artifacted points caused by subtraction with cylinder
      const sorted = GeometryUtils.sort(dplane, intersections.map(i => i.point));
      const merged = GeometryUtils.merge(sorted);
      const smoothed = GeometryUtils.smooth(merged);

      // layers.push(new Layer(p, points));
      // layers.push(new Layer(dplane, sorted));
      // layers.push(new Layer(dplane, merged));
      layers.push(new Layer(dplane, smoothed));

      /*
      const icount = intersections.length;

      // Grouping intersections by connected triangles
      const groups = this.groupIntersections(intersections);
      const polylines = groups.map(group => {
        const points = group.map(i => i.point);
        return new PolylineCurve(points);
      });
      */

      /*
      const paths: LayerType[] = groups.filter(grp => grp.length >= 2).map((grp) => {
        return {
          points: grp.map(isn => isn.point),
          closed: (grp.length === icount)
        };
      });
      // console.log(paths);

      layers.push(paths);
      */
    }

    return { origin, axis, layers, step };
  }

  private sort (plane: Plane, intersections: Intersection[]): Vector3[] {
    const { dx, dy } = GeometryUtils.getPlaneAxes(plane);
    const nn = plane.normal.normalize();

    const center = new Vector3();
    const points = intersections.map(i => i.point);
    points.forEach((p) => {
      center.add(p);
    });
    center.divideScalar(points.length);

    // const origin = this.plane.normal.clone().multiplyScalar(this.plane.constant);
    const ox = dx.dot(center);
    const oy = dy.dot(center);

    const projected = intersections.map((i) => {
      const p = i.point;
      const proj = p.clone().projectOnPlane(nn);

      const x = dx.dot(proj) - ox;
      const y = dy.dot(proj) - oy;
      return {
        i,
        angle: Math.atan2(y, -x)
      };
    });
    const sorted = projected.sort((a, b) => {
      if (a.angle < b.angle) { return -1; } else if (a.angle > b.angle) { return 1; }
      return 0;
    });

    // this.points = sorted.map(r => r.point);
    // return sorted.map(r => r.point);
    return sorted.map(r => r.i.point);
  }
}
