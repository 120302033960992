

import { Euler } from 'three';
import { ref, defineComponent, reactive, onMounted, watch } from 'vue';

const Deg2Rad = Math.PI / 180;
const Rad2Deg = 180 / Math.PI;

export default defineComponent({
  name: 'TransformForm',
  props: {
    rotation: {
      type: Euler,
      required: true,
      default: () => new Euler(),
    }
  },
  emits: [
    "update:rotation"
  ],
  setup(props, { emit }) {
    const rad2deg = (angle: number): number => {
      const degree = angle * Rad2Deg;
      const result = degree + 180;
      if (result >= 360) {
        return 0;
      }
      return result;
    };

    const deg2rad = (angle: number): number => {
      const rad = angle * Deg2Rad;
      return rad - Math.PI;
    }

    watch(() => props.rotation, (c) => {
    });

    return {
      // rotation: props.rotation,
      coordinates: ['x', 'y', 'z'],
      rad2deg,
      deg2rad,
      input: (index: number, value: string) => {
        const array = props.rotation.toArray();
        const n = Number(value);
        array[index] = deg2rad(n);
        const v = new Euler().fromArray(array);
        if (!props.rotation.equals(v)) {
          props.rotation.copy(v);
        }
        emit("update:rotation", props.rotation);
      },
      reset: () => {
        props.rotation.copy(new Euler());
        emit("update:rotation", props.rotation);
      }
    };
  }
});

