

import { ref, defineComponent, reactive } from 'vue';
import { Camera, Quaternion, Vector3 } from 'three';

class AxisElement {
  type: string;
  x: number;
  y: number;
  z: number;

  constructor () {
    this.type = 'unknown';
    this.x = 50;
    this.y = 50;
    this.z = 0;
  }

  set (position: Vector3) {
    this.x = position.x;
    this.y = position.y;
    this.z = position.z;
  }

  get position (): Vector3 {
    return new Vector3(this.x, this.y, this.z);
  }
}

class Text extends AxisElement {
  content: string;

  constructor (content: string) {
    super();
    this.type = 'text';
    this.content = content;
  }
}

class Circle extends AxisElement {
  cls: string;
  direction: Vector3;

  constructor (cls: string, direction: Vector3) {
    super();
    this.type = 'circle';
    this.cls = cls;
    this.direction = direction;
  }
}

export default defineComponent({
  name: "WorldAxis",

  setup(props, { emit }) {
    const lx = ref<SVGLineElement | null>(null);
    const ly = ref<SVGLineElement | null>(null);
    const lz = ref<SVGLineElement | null>(null);

    const x0 = new Circle('x', new Vector3(-1, 0, 0));
    const x1 = new Circle('x', new Vector3(1, 0, 0));
    const y0 = new Circle('y', new Vector3(0, -1, 0));
    const y1= new Circle('y', new Vector3(0, 1, 0));
    const z0= new Circle('z', new Vector3(0, 0, -1));
    const z1= new Circle('z', new Vector3(0, 0, 1));
    const tx= new Text('左');
    const ty= new Text('上');
    const tz= new Text('前');

    const state = reactive<{
      elements: AxisElement[];
    }>({
      elements: [],
    });

    const update = (camera: Camera) => {
      let q = (new Quaternion()).setFromEuler(camera.rotation);
      q = q.invert();

      const r = 38;
      const off = 50;

      // Sort elements
      const elements: AxisElement[] = [x0, x1, y0, y1, z0, z1].map((c) => {
        const v = c.direction.clone().applyQuaternion(q);
        v.y *= -1;
        v.multiplyScalar(r).addScalar(off);
        c.set(v);
        return c;
      });
      elements.sort((a, b) => {
        return a.z - b.z;
      });

      tx.set(x1.position);
      ty.set(y1.position);
      tz.set(z1.position);

      elements.splice(elements.indexOf(x1) + 1, 0, tx);
      elements.splice(elements.indexOf(y1) + 1, 0, ty);
      elements.splice(elements.indexOf(z1) + 1, 0, tz);

      state.elements = elements;

      const offset = '50';
      lx.value?.setAttribute('x1', `${x1.x}`);
      lx.value?.setAttribute('y1', `${x1.y}`);
      lx.value?.setAttribute('x2', offset);
      lx.value?.setAttribute('y2', offset);

      ly.value?.setAttribute('x1', `${y1.x}`);
      ly.value?.setAttribute('y1', `${y1.y}`);
      ly.value?.setAttribute('x2', offset);
      ly.value?.setAttribute('y2', offset);

      lz.value?.setAttribute('x1', `${z1.x}`);
      lz.value?.setAttribute('y1', `${z1.y}`);
      lz.value?.setAttribute('x2', offset);
      lz.value?.setAttribute('y2', offset);
    }

    const click = (c: Circle) => {
      emit('align', c.direction);
    };

    return {
      lx,
      ly,
      lz,
      state,
      update,
      click,
    };
  }

});

